import api from '@/utils/api.js';
import { ref } from 'vue';
import { deserializeJsonApi } from "@/utils/jsonApiDeserializer.js";
import { createGlobalState } from '@vueuse/core'

export const useAuthUserState = createGlobalState(() => {
  const name = ref('');
  const avatar = ref('');
  const email = ref('');
  const tourheroDraftItinerariesCount = ref(null);
  const operatorDraftItinerariesCount = ref(null);
  const cmsEnabled = ref(false);

  async function fetch() {
    const { data } = await api.get('/api/user');
    const user = Object.keys(data).length > 0 ? await deserializeJsonApi(data) : {};

    if (Object.keys(user).length > 0) {
      name.value = user.name;
      avatar.value = user.avatar;
      email.value = user.email;
      tourheroDraftItinerariesCount.value = user.meta.tourheroDraftItinerariesCount;
      operatorDraftItinerariesCount.value = user.meta.operatorDraftItinerariesCount;
      cmsEnabled.value = user.meta.cmsEnabled;
      return user;
    }

    return null;
  }

  return {
    fetch,
    name,
    avatar,
    email,
    cmsEnabled,
    tourheroDraftItinerariesCount,
    operatorDraftItinerariesCount
  }
})
